import {isMobile} from 'react-device-detect'

const definedSlots = {};
let isOnRenderSet = false;
let previousPageCalledForAds = '';

const setTargeting = (targetingArray) => {
  targetingArray && targetingArray.forEach(targeting => {
    googletag.pubads().setTargeting(targeting.key, targeting.value);
  })
}

const refreshAds = (page) => {
  try {
    let slots = [];
    if (definedSlots['bottom_anchor']) {
      slots.push(definedSlots['bottom_anchor']);
    }

    if (definedSlots[page]) {
      slots = slots.concat(definedSlots[page])
    }

    switch (page) {
      case 'article_page':
        if (definedSlots['ht_article_rightcol_desktop']) {
          slots.push(definedSlots['ht_article_rightcol_desktop']);
        }
        break;
      case 'category_page':
      case 'subcategory_page':
        if (definedSlots['ht_rightcol_desktop']) {
          slots.push(definedSlots['ht_rightcol_desktop']);
        }
        break;

    }

    googletag.pubads().refresh(slots, {changeCorrelator: true});
  } catch (e) {
    console.log(e);
  }
}

const setOnRender = () => {
  !isOnRenderSet && googletag.pubads().addEventListener('slotRenderEnded', onRender);
  isOnRenderSet = true;
}

const onRender = (event) => {
  const slot = event.slot;
  console.log("slot id: ", slot.getSlotElementId());
  console.log('isEmpty: ', event.isEmpty)
  console.log("=============================")

  const isEmpty = event.isEmpty;

  const element = document.getElementById(slot.getSlotElementId());
  if (element) {
    const parentContainer = element.parentNode;
    const children = parentContainer.childNodes;
    let adTextNode;
    /*let isEmpty;*/
    for (let i=0; i < children.length; i++) {
      if (children[i].className === "advertisement-text") {
        adTextNode = children[i];
        break;
      }
    }
    if (!adTextNode) {
      return;
    }
    /*isEmpty = !(element.children && element.children[0] && element.children[0].childElementCount);*/
    console.log("slot id: ", slot.getSlotElementId());
    console.log('isEmpty: ', isEmpty)
    console.log("----------------------------")
    if (isEmpty) {
      adTextNode && (adTextNode.style.opacity = 0);
      parentContainer.style.display = 'none';
    } else {
      adTextNode && (adTextNode.style.opacity = 1);
      parentContainer.style.display = 'block';
    }
  }
}

export const destroyPreviousAdSlots = () => {
  if (previousPageCalledForAds === '') {
    return;
  }
  googletag && googletag.destroySlots(definedSlots[previousPageCalledForAds]);
  definedSlots[previousPageCalledForAds] = null;
}

export const destroyLongerAdSlots = () => {
  definedSlots['longerAds'] && googletag && googletag.destroySlots(definedSlots['longerAds']);
  definedSlots['longerAds'] = null;
}

export const bottomAnchor = () => {
  if (definedSlots['bottom_anchor']) {
    return;
  }
  window.googletag = window.googletag || {cmd: []};
  isMobile && googletag.cmd.push(() => {
    const anchorSlot = googletag.defineOutOfPageSlot(
      '/22218985137/healthtimes/ht_sticky',
      googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
    );
    if (anchorSlot) {
      anchorSlot.addService(googletag.pubads());
      definedSlots['bottom_anchor'] = anchorSlot;
    } else {
      console.log("Anchor Slot Not available");
    }
  });
}

export const rightSide = (divId) => {
  window.googletag = window.googletag || {cmd: []};
  let slot1;
  if (definedSlots[divId] || isMobile) {
    return
  }
  googletag.cmd.push(() => {
    if(!definedSlots[divId]) {
      slot1 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_rightcol_desktop',
          [[300, 250], [300, 600], 'fluid'],
          divId
        );
      if (slot1) {
        slot1.addService(googletag.pubads());
        definedSlots[divId] = slot1;
      }
    }
  })
}

export const homePageAds = () => {
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'home_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'home_page';

  bottomAnchor();
  if (definedSlots['home_page']) {
    return setTimeout(refreshAds, 50, 'home_page');
  }

  googletag.cmd.push(() => {
    let slot1, slot2, slot3;
    if (isMobile) {
      slot1 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent1_mobile',
          [[320, 50], [300, 100], 'fluid'],
          'ht_incontent1'
        )
      slot2 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent2_mobile',
          [[320, 50], [300, 100], 'fluid'],
          'ht_incontent2'
        )
      /*slot3 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_endcontent_mobile',
          [[320, 50], [300, 100], 'fluid'],
          'ht_endcontent'
        )*/
    } else {
      slot1 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'ht_incontent1'
        )
      slot2 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent2_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'ht_incontent2'
        )
      /*slot3 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_endcontent_desktop',
          [[728, 90], [970, 250], 'fluid'],
          'ht_endcontent'
        )*/
    }
    definedSlots['home_page'] = [];

    slot1 && slot1.addService(googletag.pubads()) && definedSlots['home_page'].push(slot1)
    slot2 && slot2.addService(googletag.pubads()) && definedSlots['home_page'].push(slot2)
    slot3 && slot3.addService(googletag.pubads()) && definedSlots['home_page'].push(slot3)

    googletag.pubads().setTargeting('page', 'homepage');

    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs();
    googletag.pubads().setCentering(true);
    googletag.pubads().enableLazyLoad();
    setOnRender();
    googletag.enableServices();

    googletag.display('ht_incontent1');
    googletag.display('ht_incontent2');
    //googletag.display('ht_endcontent');
  });
}

export const articlePageAds = (targetingArray, longAdCount) => {
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'article_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'article_page';

  bottomAnchor();
  rightSide('ht_article_rightcol_desktop')

  if (longAdCount) {
    googletag.cmd.push(() => {
      destroyLongerAdSlots();
      for (let i = 1; i <= longAdCount; i++) {
        let slot;
        if(isMobile) {
          slot = googletag
            .defineSlot(
              `/22218985137/healthtimes/ht_incontent${i+3}_mobile`,
              [[300, 250], [336, 280], [300, 600], 'fluid'],
              `ht_article_incontent-0${i}`
            )
            .addService(googletag.pubads());
        } else {
          slot = googletag
            .defineSlot(
              `/22218985137/healthtimes/ht_incontent${i+3}_desktop`,
              ['fluid', [970, 250], [728, 90]],
              `ht_article_incontent-0${i}`
            )
            .addService(googletag.pubads());
        }
        if (slot) {
          definedSlots['longerAds'] = definedSlots['longerAds'] || [];
          definedSlots['longerAds'].push(slot);
        }
      }
    })
  }

  if (definedSlots['article_page']) {
    setTargeting(targetingArray)
    return setTimeout(() => {
      refreshAds('article_page');
      for (let i = 1; i <= longAdCount; i++) {
        googletag.display(`ht_article_incontent-0${i}`);
      }
    }, 50);
  }
  googletag.cmd.push(() => {
    let slot1, slot2, slot3, slot4;
    if(isMobile) {
      slot1 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent1_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'ht_article_incontent1'
        )
        .addService(googletag.pubads());
      slot2 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent2_mobile',
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'ht_article_incontent2'
        )
        .addService(googletag.pubads());
      slot3 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent3_mobile',
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'ht_article_incontent3'
        )
        .addService(googletag.pubads());
      slot4 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_endcontent_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'ht_article_endcontent'
        )
        .addService(googletag.pubads());
    } else {
      slot1 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'ht_article_incontent1'
        )
        .addService(googletag.pubads());
      slot2 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent2_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'ht_article_incontent2'
        )
        .addService(googletag.pubads());
      slot3 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent3_desktop',
          ['fluid', [970, 250], [728, 90]],
          'ht_article_incontent3'
        )
        .addService(googletag.pubads());
      slot4 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_endcontent_desktop',
          [[728, 90], [970, 250], 'fluid'],
          'ht_article_endcontent'
        )
        .addService(googletag.pubads());
    }

    setTargeting(targetingArray)
    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs();
    googletag.pubads().setCentering(true);
    googletag.pubads().enableLazyLoad();
    setOnRender();
    googletag.enableServices();

    definedSlots['article_page'] = [];
    slot1 && definedSlots['article_page'].push(slot1)
    slot2 && definedSlots['article_page'].push(slot2)
    slot3 && definedSlots['article_page'].push(slot3)
    slot4 && definedSlots['article_page'].push(slot4)

    googletag.display("ht_article_incontent1");
    googletag.display("ht_article_incontent2");
    googletag.display("ht_article_incontent3");
    googletag.display("ht_article_endcontent");
    googletag.display("ht_article_rightcol_desktop");

    for (let i = 1; i <= longAdCount; i++) {
      googletag.display(`ht_article_incontent-0${i}`)
    }
  })
}

export const categoryPageAds = (targetingArray) => {
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'category_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'category_page';

  bottomAnchor();
  if (definedSlots['category_page']) {
    setTargeting(targetingArray)
    return setTimeout(refreshAds, 50, 'category_page');
  }
  let slot1, slot2;
  googletag.cmd.push(() => {
    if(isMobile) {
      slot1 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent1_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'ht_category_incontent1'
        )
        .addService(googletag.pubads());
      slot2 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent2_mobile',
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'ht_category_incontent2'
        )
        .addService(googletag.pubads());
      /*slot3 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent3_mobile',
          [[300, 250], [336, 280], [300, 600], 'fluid'],
          'ht_category_incontent3'
        )
        .addService(googletag.pubads());
      slot4 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_endcontent_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'ht_category_endcontent'
        )
        .addService(googletag.pubads());*/
    } else {
      slot1 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'ht_category_incontent1'
        )
        .addService(googletag.pubads());
      slot2 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent2_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'ht_category_incontent2'
        )
        .addService(googletag.pubads());
      /*slot3 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent3_desktop',
          ['fluid', [970, 250], [728, 90]],
          'ht_category_incontent3'
        )
        .addService(googletag.pubads());
      slot4 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_endcontent_desktop',
          [[728, 90], [970, 250], 'fluid'],
          'ht_category_endcontent'
        )
        .addService(googletag.pubads());*/
    }

    setTargeting(targetingArray)
    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs();
    googletag.pubads().setCentering(true);
    googletag.pubads().enableLazyLoad();
    setOnRender();
    googletag.enableServices();

    definedSlots['category_page'] = [];
    slot1 && definedSlots['category_page'].push(slot1)
    slot2 && definedSlots['category_page'].push(slot2)

    googletag.display("ht_category_incontent1");
    googletag.display("ht_category_incontent2");
    /*googletag.display("ht_category_incontent3");
    googletag.display("ht_category_endcontent");*/
  })
}

export const subCategoryPageAds = (targetingArray) => {
  window.googletag = window.googletag || {cmd: []};
  if (previousPageCalledForAds !== 'subcategory_page') {
    destroyPreviousAdSlots();
  }
  previousPageCalledForAds = 'subcategory_page';

  bottomAnchor();
  rightSide('ht_rightcol_desktop')

  if (definedSlots['subcategory_page']) {
    setTargeting(targetingArray)
    return setTimeout(refreshAds, 50, 'subcategory_page');
  }
  let slot1;
  googletag.cmd.push(() => {
    if(isMobile) {
      slot1 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent1_mobile',
          [[300, 250], [336, 280], 'fluid'],
          'ht_subcategory_incontent1'
        )
        .addService(googletag.pubads());
    } else {
      slot1 = googletag
        .defineSlot(
          '/22218985137/healthtimes/ht_incontent1_desktop',
          [[970, 250], [728, 90], 'fluid'],
          'ht_subcategory_incontent1'
        )
        .addService(googletag.pubads());
    }

    setTargeting(targetingArray)
    googletag.pubads().enableSingleRequest();
    googletag.pubads().collapseEmptyDivs();
    googletag.pubads().setCentering(true);
    googletag.pubads().enableLazyLoad();
    setOnRender();
    googletag.enableServices();
    definedSlots['subcategory_page'] = [];
    definedSlots['subcategory_page'].push(slot1);

    googletag.display("ht_subcategory_incontent1");
    googletag.display("ht_rightcol_desktop");
  })
}
